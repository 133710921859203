import { createContext } from 'react';

import { ToasterContextState } from './ToasterContextState';

/**
 * When ToasterContext is used outside of context provider, log an error.
 */

export const ToasterContext = createContext<ToasterContextState>(null);
ToasterContext.displayName = 'ToasterContext';
