import React, { FC } from 'react';

import { Box } from '@/common/components/Display/Box';

import classes from './ToastContainer.module.css';
import { ToastItem } from './ToastItem';
import { ToastMessage } from './ToastMessage';
import { ToastPosition } from './ToastPosition';

const getToasterPlacementProps = (position?: ToastPosition) => {
  switch (position) {
    case ToastPosition.TopLeft:
      return { top: '1rem', left: '1rem' };
    case ToastPosition.TopRight:
      return { top: '1rem', right: '1rem' };
    case ToastPosition.TopCenter:
      return { top: '1rem', left: '50%', transform: 'translateX(-50%)' };
    case ToastPosition.BottomRight:
      return { bottom: '1rem', right: '1rem' };
    case ToastPosition.BottomCenter:
      return { bottom: '1rem', left: '50%', transform: 'translateX(-50%)' };
    case ToastPosition.BottomLeft:
    case ToastPosition.Default:
    default:
      return { bottom: '1rem', left: '1rem' };
  }
};

interface Props {
  defaultPosition?: ToastPosition;
  messages: ToastMessage[];
  onRemove: (id: string) => void;
}

export const ToastContainer: FC<Props> = ({
  defaultPosition,
  messages,
  onRemove
}) => {
  function renderContainerWithToasts<T>(
    renderCallback: (position: ToastPosition, toastList: ToastMessage[]) => T
  ) {
    const positionToMessagesMap = new Map<ToastPosition, ToastMessage[]>();

    messages.forEach((toast) => {
      const containerPosition = toast.config?.position || defaultPosition;
      positionToMessagesMap.has(containerPosition) ||
        positionToMessagesMap.set(containerPosition, []);
      positionToMessagesMap.get(containerPosition).push(toast);
    });

    return Array.from(positionToMessagesMap, (p) => renderCallback(p[0], p[1]));
  }

  return (
    <Box pos={'relative'}>
      {renderContainerWithToasts((containerPosition, toastList) => {
        return (
          <Box
            style={getToasterPlacementProps(containerPosition)}
            key={containerPosition}
            className={classes.inner}
          >
            {toastList.map((x) => {
              return <ToastItem key={x.id} toast={x} onRemove={onRemove} />;
            })}
          </Box>
        );
      })}
    </Box>
  );
};
